<template>
  <div class="gapShow">
    <div v-if="!briefMode" class="w-full" style="border-bottom: 1px solid #dadce0;">
      <div class="flex mb-2 mt-2 w-full">
        <div class="bg-grid-color-secondary mr-2 w-full">
          <div class="m-1 flex gap-2" v-if="showQuestionNumber">
            <b>{{ $t('questao-this-index', [this.index]) }}</b>
            <label v-if="showQuestionScore">{{item.data.score ? `(${$t('valor')}: ` + formattedScore + ')': ''}}</label>
          </div>
          <div class="content-break-word question-title ml-3" v-html-safe="title"></div>
        </div>
      </div>
    </div>

    <div>
      <div class="flex sm:w-full" :key="item.id" v-if="dragItems && dragItems.length > 0  && !briefMode">
        <div class="p-2 w-full">
          <div class="flex gap-2 flex-wrap justify-center gap-drop">
            <div v-for="item in dragItems" :key="item">
              <div class="drag-item select-none">
                {{item.label}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-center items-center mb-3">

        <div v-for="option in options" :key="option" class="mt-4 ml-2">
          <div
            :class="{
              'gap-answer': option.type === 'gap',
              'magnetic-gap-answer': option.type === 'magnetic_gap'
            }"
          >
            <div class="relative">
              <div
                v-if="!isTextGap(option)"
                :class="{
                  'wrong-option' : isIncorrectedOption(option),
                  'corrected-option': isCorrectedOption(option),
                  'partially-corrected-option': isPartiallyCorrectedOption(option)
                }"
              >
                <div
                  style="width: 100%;min-height: 1.5rem;"
                >
                  {{ getAnswer(option) }}
                </div>
                <label
                  v-if="showCorrectAnswer"
                  class="absolute text-xs"
                  style="left: 0; width: max-content; bottom: -1.5rem;"
                >
                  {{ option.label }}
                </label>
              </div>
            </div>
            &nbsp;
            <span v-if="isTextGap(option) && !briefMode">
              {{ option.label }}
            </span>
          </div>
        </div>
      </div>

    </div>

    <h5
      class="mt-8 border-bottom"
      v-if="!isEmpty(item.feedback || feedback) && !briefMode"
    ></h5>
    <h5 class="mt-8 border-bottom" v-if="!isEmpty(item.feedback || feedback) && !briefMode">{{ $t('feedback-do-professor') }}</h5>
    <div class="flex sm:w-full" v-if="!isEmpty(item.feedback || feedback) && !briefMode">
      <div class="bg-grid-color-secondary mr-2 mb-2 pt-2 w-full">
        <div v-html-safe="item.feedback || feedback"></div>
      </div>
    </div>

    <h5 class="mt-8 border-bottom" v-if="invalidated && !briefMode"></h5>
    <h5 class="mt-8 text-danger" v-if="invalidated && !briefMode">{{ $t('justificativa-da-anulacao') }}</h5>
    <div class="flex sm:w-full" v-if="invalidated && !briefMode">
      <div class="bg-grid-color-secondary mr-2 mb-2 pt-2 w-full">
        <div v-html-safe="invalidated"></div>
      </div>
    </div>
  </div>
</template>

<script>

import MediaService from '@/services/api/MediaService'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        feedback: ''
      })
    },
    highlightCorrectBackground: {
      type: Boolean,
      default: true
    },
    showCorrectAnswer: {
      type: Boolean,
      default: true
    },
    briefMode: {
      type: Boolean,
      default: false
    },
    showQuestionNumber:{
      type: Boolean,
      default: false
    },
    showQuestionScore:{
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    options: [],
    key: 0
  }),
  computed: {
    title() {
      const title = this.item.data.title ? this.item.data.title : this.$t('sem-titulo')
      return title
    },
    invalidated() {
      const invalidated = this._.get(this.item, 'data.invalidated.justification', false)
      if (invalidated !== false) {
        return invalidated
      }

      return false
    },
    dragItems() {
      const options = this._.get(this.item, 'data.options', []) ||[]
      const answers = this._.get(this.item, 'answer', []) || []
      return options.filter((option) => {
        const type = this._.get(option, 'type')
        if (type === 'magnetic_gap') {
          const optionId = this._.get(option, 'id',)

          const answered = answers.findIndex((answer) => {
            const isMagnetic = this._.get(options.find((option) => option.id === answer.id), 'type') === 'magnetic_gap'
            return (
              isMagnetic &&
              answer && !this.isEmpty(answer.answer) && answer.answer === option.label
            )
          })
          return answered === -1 // -1 Means there is no filled answer for this magnetic_gap option
        } else {
          return false
        }
      })
    },
    formattedScore() {
      const score = this.item.data.score
      return score ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    }
  },
  methods: {
    getAnswer(option) {
      const labelLength = (_.get(option, 'label', '') || '').length
      const padAnswer = (str) => _.pad(str, labelLength, ' ')
      const blank = _.repeat(' ',  (_.get(option, 'label', '') || '').length)
      if (option.type === 'gap' || option.type === 'magnetic_gap') {
        const answers = this._.get(this.item, 'answer', []) || []
        const answer = answers.find(element => {
          return element.id === option.id
        })

        return padAnswer(this._.get(answer, 'answer')) || blank
      }
      return blank
    },
    optionCorrectionIs(correction, option) {
      if (this.item && this.item.answer && this._.isArray(this.item.answer)) {
        const answer = this.item.answer.find(element => {
          return element.id === option.id
        })
        if (answer && answer.correction === correction) {
          return true
        }
        return false
      }
      // If the user hasnt answered the question, returns null instead of bool.
      return null
    },
    optionAnswerIsEmpty(option) {
      const answers = _.get(this.item, 'answer', []) || []
      const answer = _.find(answers, _.matchesProperty('id', _.get(option, 'id')))

      if (answer) return _.isEmpty(answer.answer)
      else return true
    },
    isTextGap(option) {
      return option.type === 'text'
    },
    isCorrectedOption(option) {
      const correct = this.optionCorrectionIs('C', option)
      return correct === null ? false : correct
    },
    isPartiallyCorrectedOption(option) {
      const partiallyCorrect = this.optionCorrectionIs('P', option)
      return partiallyCorrect === null ? false : partiallyCorrect
    },
    isIncorrectedOption(option) {
      const incorrect = this.optionCorrectionIs('I', option)
      return incorrect === null ? true : incorrect
    },
    showOptionLabel(option) {
      const optionIsIncorrect = this.optionCorrectionIs('I', option)

      switch (option.type) {
      case 'magnetic_gap':
      case 'gap':
        const empty = this.optionAnswerIsEmpty(option)
        return (optionIsIncorrect || empty) && this.showCorrectAnswer
      default:
        return true
      }
    },
    borderClass(option) {
      const baseClass = 'vx-row py-1 sm:py-auto'
      if (this.highlightCorrectBackground) {
        return option.chosen
          ? this.item.correction
            ? `${baseClass} bg-green-light bg-opacity-25`
            : `${baseClass} bg-red-light bg-opacity-25`
          : `${baseClass}`
      } else {
        return baseClass
      }
    },
    teacherMark(option) {
      const classes = 'ml-2 float-left absolute'
      return !option.selected ? `${classes} invisible` : `${classes}`
    },
  },
  beforeMount() {
    this.mediaService = MediaService.build(this.$vs)

    if (this.item) {
      this.options =
        this.item.data && this.item.data.options ? this.item.data.options : []
      this.options.forEach(element => {
        element.chosen = false
      })

      for (let index = 0; index < this.options.length; index++) {
        const element = this.options[index]
        if (element.id === this.item.chosen_option_id) {
          element.chosen = true
        }
      }
    }
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss">

.success-border {
  border: 1px solid green;
  border-radius: 8px;
  padding: 5px;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
  padding: 5px;
}

.gapShow {
  .drag-item {
    min-width: 80px;
    padding: 10px;
    background: #777;
    border-radius: 5px;
    color: white;
  }

  .drag-item-disabled {
    background: $grey;
  }

  .dragged-item {
    &.corrected-option {
      background-color: $success !important;
    }
    padding: 1px;
    border-radius: 2px;
    background: #777;
    color: white;
  }
}
</style>
