var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gapShow" }, [
    !_vm.briefMode
      ? _c(
          "div",
          {
            staticClass: "w-full",
            staticStyle: { "border-bottom": "1px solid #dadce0" },
          },
          [
            _c("div", { staticClass: "flex mb-2 mt-2 w-full" }, [
              _c(
                "div",
                { staticClass: "bg-grid-color-secondary mr-2 w-full" },
                [
                  _vm.showQuestionNumber
                    ? _c("div", { staticClass: "m-1 flex gap-2" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.$t("questao-this-index", [this.index]))
                          ),
                        ]),
                        _vm.showQuestionScore
                          ? _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.item.data.score
                                    ? "(" +
                                        _vm.$t("valor") +
                                        ": " +
                                        _vm.formattedScore +
                                        ")"
                                    : ""
                                )
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("div", {
                    directives: [
                      {
                        name: "html-safe",
                        rawName: "v-html-safe",
                        value: _vm.title,
                        expression: "title",
                      },
                    ],
                    staticClass: "content-break-word question-title ml-3",
                  }),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", [
      _vm.dragItems && _vm.dragItems.length > 0 && !_vm.briefMode
        ? _c("div", { key: _vm.item.id, staticClass: "flex sm:w-full" }, [
            _c("div", { staticClass: "p-2 w-full" }, [
              _c(
                "div",
                { staticClass: "flex gap-2 flex-wrap justify-center gap-drop" },
                _vm._l(_vm.dragItems, function (item) {
                  return _c("div", { key: item }, [
                    _c("div", { staticClass: "drag-item select-none" }, [
                      _vm._v(" " + _vm._s(item.label) + " "),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-center items-center mb-3" },
        _vm._l(_vm.options, function (option) {
          return _c("div", { key: option, staticClass: "mt-4 ml-2" }, [
            _c(
              "div",
              {
                class: {
                  "gap-answer": option.type === "gap",
                  "magnetic-gap-answer": option.type === "magnetic_gap",
                },
              },
              [
                _c("div", { staticClass: "relative" }, [
                  !_vm.isTextGap(option)
                    ? _c(
                        "div",
                        {
                          class: {
                            "wrong-option": _vm.isIncorrectedOption(option),
                            "corrected-option": _vm.isCorrectedOption(option),
                            "partially-corrected-option":
                              _vm.isPartiallyCorrectedOption(option),
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "min-height": "1.5rem",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.getAnswer(option)) + " ")]
                          ),
                          _vm.showCorrectAnswer
                            ? _c(
                                "label",
                                {
                                  staticClass: "absolute text-xs",
                                  staticStyle: {
                                    left: "0",
                                    width: "max-content",
                                    bottom: "-1.5rem",
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.label) + " ")]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v("   "),
                _vm.isTextGap(option) && !_vm.briefMode
                  ? _c("span", [_vm._v(" " + _vm._s(option.label) + " ")])
                  : _vm._e(),
              ]
            ),
          ])
        }),
        0
      ),
    ]),
    !_vm.isEmpty(_vm.item.feedback || _vm.feedback) && !_vm.briefMode
      ? _c("h5", { staticClass: "mt-8 border-bottom" })
      : _vm._e(),
    !_vm.isEmpty(_vm.item.feedback || _vm.feedback) && !_vm.briefMode
      ? _c("h5", { staticClass: "mt-8 border-bottom" }, [
          _vm._v(_vm._s(_vm.$t("feedback-do-professor"))),
        ])
      : _vm._e(),
    !_vm.isEmpty(_vm.item.feedback || _vm.feedback) && !_vm.briefMode
      ? _c("div", { staticClass: "flex sm:w-full" }, [
          _c(
            "div",
            { staticClass: "bg-grid-color-secondary mr-2 mb-2 pt-2 w-full" },
            [
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.item.feedback || _vm.feedback,
                    expression: "item.feedback || feedback",
                  },
                ],
              }),
            ]
          ),
        ])
      : _vm._e(),
    _vm.invalidated && !_vm.briefMode
      ? _c("h5", { staticClass: "mt-8 border-bottom" })
      : _vm._e(),
    _vm.invalidated && !_vm.briefMode
      ? _c("h5", { staticClass: "mt-8 text-danger" }, [
          _vm._v(_vm._s(_vm.$t("justificativa-da-anulacao"))),
        ])
      : _vm._e(),
    _vm.invalidated && !_vm.briefMode
      ? _c("div", { staticClass: "flex sm:w-full" }, [
          _c(
            "div",
            { staticClass: "bg-grid-color-secondary mr-2 mb-2 pt-2 w-full" },
            [
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.invalidated,
                    expression: "invalidated",
                  },
                ],
              }),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }